<template>
    <div>
        <Transition name="fade">
            <div
                v-if="displayBackground"
                class="absolute inset-0 z-[10] size-full bg-violet/80"
            ></div>
        </Transition>
        <VueDatePicker
            ref="datepicker"
            :model-value="date"
            :range="{ minRange: 1 }"
            locale="fr"
            :teleport-center="true"
            :placeholder="displayValue"
            :enable-time-picker="false"
            :auto-apply="true"
            input-format="dd/MM/yyyy"
            :format="format"
            :min-date="new Date()"
            :start-date="defaultStartDate"
            :end-date="defaultEndDate"
            @update:model-value="handleDate"
            @closed="handleClose"
            @open="handleOpen"
        >
            <template #input-icon> </template>
            <template #action-row>
                <div class="action-row">
                    <button
                        class="center flex w-full items-center justify-center space-x-2 rounded-full bg-violet px-8 py-2 font-Poppins text-white"
                        @click="handleSubmit"
                    >
                        Valider
                    </button>
                </div>
            </template>
        </VueDatePicker>
    </div>
</template>

<script setup>
import { useDayjs } from '#dayjs';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const dayjs = useDayjs();
dayjs.locale('fr');

const date = ref(null);
const displayValue = ref('Arrivée - Départ');
const datepicker = ref(null);

const displayBackground = ref(false);

const defaultStartDate = ref(dayjs().add(2, 'week').day(5).toDate());
const defaultEndDate = ref(dayjs().add(2, 'week').day(7).toDate());

const emit = defineEmits(['SelectedDate']);

const formatDate = (dateObj) => {
    if (!dateObj) return '';
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
};

const handleDate = (modelData) => {
    date.value = modelData;
    emit('SelectedDate', modelData);
};

const handleOpen = (value) => {
    displayBackground.value = true;
};

const format = (date) => {
    return `${dayjs(date[0]).format('ddd DD').toString()} - ${dayjs(date[1]).format('ddd DD MMM').toString()}`;
};
const handleSubmit = () => {
    if (date.value && Array.isArray(date.value)) {
        const [startDate, endDate] = date.value;

        displayValue.value = `${customFormat(startDate)} - ${customFormat(endDate)}`;
        datepicker.value.closeMenu();
    }
};
const handleClose = () => {
    if (!date.value) {
        displayValue.value = 'Arrivée - Départ';
    }
    displayBackground.value = false;
};

onMounted(() => {
    date.value = [defaultStartDate.value, defaultEndDate.value];
    emit('SelectedDate', [defaultStartDate.value, defaultEndDate.value]);
});
</script>

<style lang="scss">
.action-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
:root {
    --dp-font-family: 'Lexend', sans-serif;
    --dp-input-icon-padding: 25px;
}
.dp__input_wrap input {
    --dp-border-radius: 9999px;
    font-size: 14px;
    font-weight: 400 !important;
    font-family: 'Poppins', sans-serif;
    font-size: 0.775rem /* 12px */;
}
.dp__input::placeholder {
    opacity: 1 !important;
    /*font-style: italic;*/
    font-weight: 400 !important;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 1rem /* 16px */;
    --tw-text-opacity: 1;
    color: #a9a9a9 !important;
}
.dp__theme_light {
    --dp-primary-color: #005bbf;
    --dp-hover-color: #bbdcff;
}
.dp__input {
    font-family: 'Poppins', sans-serif !important;
    font-size: 14px !important;
    line-height: 1rem !important;
    height: 32px !important;
    border-radius: 9999px !important;
    padding: 0.25rem 1rem !important;
    color: #13181a !important;
}

.dp__ .dp__input_wrap input {
    font-family: 'Poppins', sans-serif !important;
    font-size: 0.775rem !important;
}

.dp__input:focus {
    outline: none;
    box-shadow: none;
}
</style>
