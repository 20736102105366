export const useSearch = () => {
    const client = useSupabaseClient()
    const loading = ref(false)
    const results = ref([])
    const error = ref(null)
    const totalCount = ref(0)
    const currentPage = ref(1)
    const pageSize = ref(10)

    const groupedResults = computed(() => ({
        destinations: results.value.filter(r => r.result_type === 'destination'),
        activities: results.value.filter(r => r.result_type === 'activity')
    }))

    const totalPages = computed(() =>
        Math.ceil(totalCount.value / pageSize.value)
    )

    const search = async (term, page = 1, size = 10) => {
        if (!term) {
            results.value = []
            return
        }

        loading.value = true;
        error.value = null;

        try {
            const { data, error: searchError } = await client
                .rpc('search_content', {
                    search_term: term,
                    page_number: page,
                    page_size: size
                })

            if (searchError) throw searchError

            if (data && data.length > 0) {
                if (page === 1) {
                    results.value = data;
                } else {
                    results.value = [...results.value, ...data];
                }
                currentPage.value = page;
                totalCount.value = data[0].total_count;
            } else if (page === 1) {
                results.value = [];
                totalCount.value = 0;
            }
        } catch (e) {
            error.value = e instanceof Error ? e.message : 'Search failed'
            if (page === 1) results.value = []
        } finally {
            loading.value = false
        }
    }

    return {
        search,
        results,
        groupedResults,
        loading,
        error,
        currentPage,
        pageSize,
        totalPages,
        totalCount
    }
}