import { toast, type ToastOptions } from 'vue3-toastify';

export const useToast = () => {
    const defaultOptions: ToastOptions = {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        theme: 'dark',
        hideProgressBar: true,
        closeOnClick: true,
        className: 'custom-dark-toast',
    };

    const showSuccess = (message: string, options?: ToastOptions) => {
        toast.success(message, {
            ...defaultOptions,
            ...options,
        });
    };

    const showError = (message: string, options?: ToastOptions) => {
        toast.error(message, {
            ...defaultOptions,
            ...options,
        });
    };

    const showInfo = (message: string, options?: ToastOptions) => {
        toast.info(message, {
            ...defaultOptions,
            ...options,
        });
    };

    const showWarning = (message: string, options?: ToastOptions) => {
        toast.warning(message, {
            ...defaultOptions,
            ...options,
        });
    };

    return {
        showSuccess,
        showError,
        showInfo,
        showWarning,
    };
};
