const GEO_COOKIE_NAME = 'exod-cf-geo';
const COOKIE_OPTIONS = {
    path: '/',
    secure: process.env.NODE_ENV === 'production',
    httpOnly: false,
    maxAge: 60 * 60 * 1 // 1h
};
const DEBUG = process.env.NODE_ENV !== 'production';

const HEADERS_MAP = {
    'cf-ipcity': 'city',
    'cf-ipcountry': 'country',
    'cf-ipcontinent': 'continent',
    'cf-iplongitude': 'longitude',
    'cf-iplatitude': 'latitude',
    'cf-region': 'region',
    'cf-region-code': 'region_code',
    'cf-metro-code': 'metro_code',
    'cf-postal-code': 'postal_code',
    'cf-timezone': 'timezone'
};

// Mock data for development
const DEV_MOCK_HEADERS = {
    'cf-ipcity': 'Paris',
    'cf-ipcountry': 'FR',
    'cf-ipcontinent': 'EU',
    'cf-iplongitude': '2.3522',
    'cf-iplatitude': '48.8566',
    'cf-region': 'Ile-de-France',
    'cf-region-code': 'IDF',
    'cf-metro-code': '751',
    'cf-postal-code': '75001',
    'cf-timezone': 'Europe/Paris'
};

export default defineNuxtRouteMiddleware(() => {
    try {
        const geoCookie = useCookie(GEO_COOKIE_NAME, COOKIE_OPTIONS);

        if (geoCookie.value) {
            return;
        }

        const event = useRequestEvent();
        if (!event) return;

        const headers = DEBUG
            ? { ...event.node.req.headers, ...DEV_MOCK_HEADERS }
            : event.node.req.headers;


        const geoData = {};
        Object.entries(HEADERS_MAP).forEach(([header, key]) => {
            const value = headers[header];
            if (value) {
                geoData[key] = value;
            }
        });

        if (Object.keys(geoData).length > 0) {
            geoCookie.value = JSON.stringify(geoData);
        }
    } catch (error) {
        console.error('Error in geolocation middleware:', error);
    }
});