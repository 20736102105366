export const useRecombee = () => {
    const config = useRuntimeConfig();
    const client = ref(null);
    let rqs = null;

    const initClient = async () => {
        if (import.meta.server) {
            //console.log('Server side useRecombee');
            const recombee = await import('recombee-api-client');
            rqs = recombee.requests;
            client.value = new recombee.ApiClient(
                config.public.recombee.database,
                config.recombeeApiKey,
                { region: config.public.recombee.region }
            );
        } else {
            //console.log('Client side useRecombee');
            const recombee = await import('recombee-js-api-client');
            rqs = recombee;
            client.value = new recombee.ApiClient(
                config.public.recombee.database,
                config.public.recombee.token,
                { region: config.public.recombee.region }
            );
        }
    };
    //
    const getRecommendations = async ({
        userId = `defaultUserId_${Date.now()}`,
        coordinates = null,
        limit = 20,
        countryCode = null,
        initialDistance = 100,
        maxDistance = 500,
        distanceIncrement = 30,
        customScenario = null,
    } = {}) => {
        await initClient();

        console.log('getRecommendantions parameters:', {
            userId,
            coordinates,
            limit,
            countryCode,
        });

        try {
            let gpsScenario = 'gps';
            let countryScenario = 'pays';
            if (userId.startsWith('defaultUserId_')) {
                gpsScenario = 'logged_out_gps';
                countryScenario = 'logged_out_pays';
            }

            let additionnalFilter = `AND ['lat','lng'] NOT IN geo_radius([${coordinates.lat}, ${coordinates.lng}], 50)`;
            if (customScenario && coordinates) {
                gpsScenario = customScenario;
                additionnalFilter = '';
            }

            if (!coordinates && !countryCode) {
                countryCode = 'fr';
            }

            // If we don't have coordinates, just make a single request with country code
            if (!coordinates) {
                const options = {
                    scenario: countryScenario,
                    cascadeCreate: true,
                    returnProperties: true,
                    filter: `'country_code' == "${countryCode}"`
                };

                console.log('getRecommendations with country options', options);

                const request = new rqs.RecommendItemsToUser(userId, limit, options);

                return await client.value.send(request);
            }

            // If we have coordinates, try with increasing distances
            let currentDistance = initialDistance;
            let recommendations;

            while (currentDistance <= maxDistance) {
                console.log(`Trying to find recommendations within ${currentDistance}km`);

                const options = {
                    scenario: gpsScenario,
                    cascadeCreate: true,
                    returnProperties: true,
                    filter: `['lat','lng'] IN geo_radius([${coordinates.lat}, ${coordinates.lng}], ${currentDistance}) ${additionnalFilter}`,
                    booster: `1/(1+earth_distance(${coordinates.lat}, ${coordinates.lng}, 'lat', 'lng'))`
                };

                console.log('getRecommendations with GPS options', options);

                const request = new rqs.RecommendItemsToUser(userId, limit, options);
                recommendations = await client.value.send(request);

                if (recommendations.recomms && recommendations.recomms.length > 0) {
                    console.log(`Found ${recommendations.recomms.length} recommendations within ${currentDistance}km`);

                    return recommendations;
                }

                currentDistance += distanceIncrement;
            }

            // If we get here and still have no recommendations, try one last time with maxDistance
            if (!recommendations?.recomms?.length) {
                console.log(`Last attempt: trying to find recommendations within ${maxDistance}km`);

                const options = {
                    scenario: gpsScenario,
                    cascadeCreate: true,
                    returnProperties: true,
                    filter: `['lat','lng'] IN geo_radius([${coordinates.lat}, ${coordinates.lng}], ${maxDistance})`
                };

                const request = new rqs.RecommendItemsToUser(userId, limit, options);
                recommendations = await client.value.send(request);
            }

            return recommendations;
        } catch (error) {
            console.error('Recombee RecommendItemsToUser error:', error);
            throw error;
        }
    };

    const getNextRecommendations = async (recommId, limit = 10) => {
        await initClient();

        try {
            console.log('getNextRecommendations for recommId', recommId);
            const request = new rqs.RecommendNextItems(recommId, limit);

            return await client.value.send(request);
        } catch (error) {
            console.error('Recombee RecommendNextItems error:', error);

            throw error;
        }
    };

    const sendEvent = async (userId, itemId, eventType, options = {}) => {
        await initClient();

        if (!userId || !itemId || !eventType) throw new Error('Missing parameters');

        try {
            const eventOptions = {
                cascadeCreate: true,
                timestamp: new Date().toISOString(),
                ...options
            };

            const requestTypes = {
                detailView: rqs.AddDetailView,
                purchase: rqs.AddPurchase,
                bookmark: rqs.AddBookmark,
                cartAddition: rqs.AddCartAddition
            };

            const RequestClass = requestTypes[eventType];
            if (!RequestClass) throw new Error(`Invalid event: ${eventType}`);

            return await client.value.send(new RequestClass(userId, itemId, eventOptions));
        } catch (error) {
            console.error(`Recombee ${eventType} error:`, error);

            throw error;
        }
    };

    const isRecommIdExpiredOrMissing = (recombee, user) => {
        //if (!user) {
        //    console.debug('User is not logged in');
        //    return true;
        //}

        if (!recombee?.recommId) {
            console.log('recombee or recommId missing');

            return true;
        }

        if (!recombee?.recommIdTtl) {
            console.log('RecommId TTL is missing');

            return true;
        }

        const currentTime = Math.floor(Date.now() / 1000);
        const isExpired = recombee.recommIdTtl < currentTime;

        if (isExpired) {
            console.log(`RecommId expired at ${new Date(recombee.recommIdTtl * 1000).toISOString()}`);
        }

        return isExpired;
    };

    return {
        getRecommendations,
        getNextRecommendations,
        isRecommIdExpiredOrMissing,
        sendAddDetailViewEvent: (userId, itemId, options) => sendEvent(userId, itemId, 'detailView', options),
        sendAddPurchaseEvent: (userId, itemId, options) => sendEvent(userId, itemId, 'purchase', options),
        sendAddBookmarkEvent: (userId, itemId, options) => sendEvent(userId, itemId, 'bookmark', options),
        sendAddCartAdditionEvent: (userId, itemId, options) => sendEvent(userId, itemId, 'cartAddition', options)
    };
};
