import debounce from 'debounce';

export const useSearchAutocomplete = (options = {}) => {
    const {
        debounceMs = 300,
        limit = 20,
        lang = 'fr',
        countryCodes = null,
    } = options;


    const searchTerm = ref('');
    const results = ref([]);
    const isLoading = ref(false);
    const error = ref(null);
    const hasResults = computed(() => results.value?.length > 0);

    const clearResults = () => {
        results.value = [];
        error.value = null;
    };

    const fetchResults = async () => {
        try {
            const trimmedTerm = searchTerm.value.trim();
            const response = await $fetch('/api/search/autocomplete', {
                params: {
                    q: trimmedTerm,
                    limit,
                    lang,
                    format: 'json',
                    addressdetails: 1,
                    normalizecity: 1,
                    countryCodes: countryCodes?.join(','),
                }
            });
            if (!response.success) throw new Error(response.error);
            results.value = response.data;
        } catch (err) {
            console.error('Search error:', err);
            error.value = 'Failed to perform search';
            results.value = [];
        }
    };

    const performSearch = debounce(async () => {
        const trimmedTerm = searchTerm.value.trim();
        if (!trimmedTerm || trimmedTerm.length < 3) {
            clearResults();
            return;
        }
        isLoading.value = true;
        await fetchResults();
        isLoading.value = false;
    }, debounceMs);

    watch(searchTerm, performSearch);

    return {
        searchTerm,
        results,
        isLoading,
        error,
        hasResults,
        clearResults
    };
};